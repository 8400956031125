import React from "react";
import CustomButton from "../../common/CustomBtn";
import CustomInput from "../../common/CustomInpute";
import personImage from "../../../assets/images/Profile photo.png";
import DataItem from "./DataItem";
import { useUnderReview } from "../../../context/underPreview/UnderPreview.js";
function WorkerPreviewContent({ data, id }) {
  console.log(data);
  const { changeStatus } = useUnderReview();
  console.log("from table of worker data");
  console.log(data.data);
  return (
    <div className=" m-4 md:m-12 flex w-full items-center lg:items-start mx-auto justify-center gap-4 flex-col ">
      <div className="flex justify-center md:justify-start flex-wrap items-center gap-7 mt-7 p-2 md:p-4">
        {data.imageUrl && (
          <div className="flex items-center gap-3 flex-col">
            <h3 className="text-sm text-mainTextColor">Image</h3>
            <img
              className="w-32  rounded-lg h-20 "
              src={data.imageUrl || personImage}
              alt="Camera"
            />
          </div>
        )}
        {data.frontID && (
          <div className="flex items-center gap-3 flex-col">
            <h3 className="text-sm text-mainTextColor">Front ID</h3>
            <img
              className="w-32  rounded-lg h-20 "
              src={data.frontID || personImage}
              alt="Camera"
            />
          </div>
        )}
        {data.backID && (
          <div className="flex items-center gap-3 flex-col">
            <h3 className="text-sm text-mainTextColor">Back ID</h3>
            <img
              className="w-32  rounded-lg h-20 "
              src={data.backID || personImage}
              alt="Camera"
            />
          </div>
        )}
        {data.selfie && (
          <div className="flex items-center gap-3 flex-col">
            <h3 className="text-sm text-mainTextColor">Selfie</h3>
            <img
              className="w-32  rounded-lg h-20 "
              src={data.selfie || personImage}
              alt="Camera"
            />
          </div>
        )}
      </div>

      <div className="flex items-center w-full flex-wrap my-10 gap-10">
        <div className="flex flex-col gap-6 items-center w-full  md:w-auto justify-center">
          <DataItem title="Full Name" dataItem={data.fullName} />
          <DataItem title="Phone Number" dataItem={data.phoneNumber} />
          <DataItem title="Status" dataItem={data.status} />
        </div>
        <div className="flex flex-col gap-6 items-center w-full  md:w-auto justify-center">
          <DataItem title="Email" dataItem={data.email} />
          <DataItem title="Bank Number" dataItem={data.bankNumber} />
          <DataItem
            title=" Experience Country"
            dataItem={data.experience_Country}
          />
        </div>
      </div>
      {/* <div className=" flex items-center justify-center gap-10 ">
        <CustomButton
          label="Certified"
          className=" w-full !shadow-md !rounded-md p-2 sm:!w-[200px] "
          onClick={() => {
            changeStatus(data._id, "Certified");
          }}
        />
        <CustomButton
          label="Ban"
          className={`w-full !shadow-md !rounded-md p-2 sm:!w-[200px]  !bg-red-600 `}
          onClick={() => {
            changeStatus(id, "Certified");
          }}
        />
      </div> */}
    </div>
  );
}

export default WorkerPreviewContent;
