import React, { useState } from "react";
import admin from "../../../assets/images/admin.png";
import { BsThreeDots } from "react-icons/bs";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";

function AdminComponent({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open;

  return (
    <div className="bg-white rounded-md my-7 shadow-sm p-4 sm:p-6 flex items-center justify-between">
      <div className="flex items-center gap-4">
        <img src={admin} className="w-5 sm:w-8" alt="admin" />
        <h3 className="text-[10px] sm:text-sm text-mainTextColor">
          {data.userName}
        </h3>
      </div>
      <h3 className="text-[10px] sm:text-sm text-mainTextColor">
    {data.email}
      </h3>
      <h3 className="text-[10px] sm:text-sm text-mainTextColor">Admin</h3>

      <BsThreeDots
        size={20}
        aria-describedby={id}
        onClick={() =>{}}
        className="text-fiveTextColor cursor-pointer"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="p-4 flex flex-col gap-2">
          <button className="outline-none border-none rounded-md flex items-center gap-4 bg-blue-100 transition-all focus:scale-110 text-white px-4 py-2">
            <FiEdit size={18} className="text-blue-600" />
            <h3 className="text-sm text-blue-600 "> Edit </h3>
          </button>
          <button className="outline-none border-none rounded-md flex items-center gap-4 bg-red-100 transition-all focus:scale-110 text-white px-4 py-2">
            <AiFillDelete size={18} className="text-red-600" />
            <h3 className="text-sm text-red-600 "> Delete </h3>
          </button>
        </div>
      </Popover>
    </div>
  );
}

export default AdminComponent;
