
import client from "../client";

// Get all chats with messages
export const getChats = async () => {
  const response = await client.get(
    "api/Chat/conversations"
  );
  return response.data;
};

// Send a message
export const sendMessage = async (receiverId, content) => {
  try {
    const response = await client.post("api/Chat/send-mail", {
      receiverId,
      content,
    });
    return response; // Return the full response for further handling
  } catch (error) {
    console.error("Error sending message:", error);
    throw error; // Propagate error for handling in the calling function
  }
};
