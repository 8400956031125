// src/components/Header.js

import React from "react";

const HeaderTitle = ({ className = "", title, imgSrc }) => {
  return (
    <header className={`bg-white shadow-sm p-4 ${className}`}>
      <div className="flex justify-end md:justify-start ml-12 items-center gap-4">
        {imgSrc && <img src={imgSrc} className="w-8" alt={title} />}
        <h3 className="text-md md:text-lg">{title}</h3>
      </div>
    </header>
  );
};

export default HeaderTitle;
