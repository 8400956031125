import React, { createContext, useContext, useEffect, useState } from "react";
import {
  fetchDashboardStats,
  fetchDashboardCompletedOrders,
} from "../../api/dashboard/dashBoardApi";

const HomeContext = createContext();

export const useHome = () => {
  return useContext(HomeContext);
};

export const HomeProvider = ({ children }) => {
  const [homeData, setHomeData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [completedOrder, setCompletedOrder] = useState([]);

  const getCompletedOrder = async () => {
    try {
      const data = await fetchDashboardCompletedOrders();
      console.log("from preview api ", data);
      setCompletedOrder(data.data);
    } catch (err) {
    } finally {
    }
  };
  useEffect(() => {
    const getHomeData = async () => {
      setLoading(true);
      try {
        const data = await fetchDashboardStats();

        setHomeData(data);
      } catch (err) {
        console.log(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getHomeData();
    getCompletedOrder();
  }, []);

  const value = {
    homeData,
    loading,
    error,
    completedOrder,
  };

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
};
