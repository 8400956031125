// src/components/NavItem.js

import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavItem = ({ to, imgSrc, text }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li
      className={`transition-all py-1 hover:bg-primaryOpacity w-full ${
        isActive ? "bg-primaryOpacity" : ""
      }`}
    >
      <Link
        to={to}
        className={`py-2 flex w-[80%] mx-auto justify-start items-center gap-6 hover:text-primary transition-all ${
          isActive ? "text-primary" : ""
        }`}
      >
        <img src={imgSrc} className="w-8" alt={text} />
        <h3 className=" text-md md:text-lg">{text}</h3>
      </Link>
    </li>
  );
};

export default NavItem;
