import React, { createContext, useContext, useEffect, useState } from "react";
import {
  fetchUnderReviewData,
  fetchWorker,
  changeWorkerStatus,
} from "../../api/underPreview/underPreview";

const UnderReviewContext = createContext();

export const useUnderReview = () => {
  return useContext(UnderReviewContext);
};

export const UnderReviewProvider = ({ children }) => {
  const [underReviewData, setUnderReviewData] = useState(null);
  const [workerData, setWorkerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const getData = async () => {
    try {
      const data = await fetchUnderReviewData();
      console.log("from preview api ", data);
      setUnderReviewData(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const fetchWorkerById = async (id) => {
    setLoading(true);
    try {
      const data = await fetchWorker(id);
      setWorkerData(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const filteredData = async (search, sort) => {
    const data = await fetchUnderReviewData(search, sort);
    console.log(data);
    setUnderReviewData(data);
  };
  const changeStatus = async (id, status, message) => {
    try {
      await changeWorkerStatus(id, status, message);
      getData();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <UnderReviewContext.Provider
      value={{
        underReviewData,
        loading,
        error,
        workerData,
        fetchWorkerById,
        changeStatus,
        filteredData,
      }}
    >
      {children}
    </UnderReviewContext.Provider>
  );
};
