import React from "react";
import down from "../../../assets/images/No.png"; // Fixed image for percentage indicator

function StaticContainerAnalyzer({ 
  img, 
  altText, 
  title, 
  subtitle, 
  amount, 
  percentage 
}) {
  return (
    <div className="bg-white w-[320px] p-4 rounded-2xl shadow-sm">
      {/* Image */}
      <img className="w-12" src={img} alt={altText || "Image"} />

      {/* Title and Subtitle */}
      <div className="mt-4 flex justify-between items-center">
        <h3 className="text-sm font-medium text-[#333333]">{title}</h3>
        <h3 className="text-sm font-thin text-[#00261C]">{subtitle}</h3>
      </div>

      {/* Amount */}
      <h3 className="text-xl font-medium text-[#00261C]">{amount}</h3>

      {/* Percentage Section */}
      <div className="flex items-center gap-1">
        <img className="w-4" src={down} alt="Percentage Indicator" />
        <p className="text-thirdTextColor text-sm">{percentage}</p>
      </div>
    </div>
  );
}

export default StaticContainerAnalyzer;
