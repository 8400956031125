import React, { useState, useCallback } from "react";
import { AiOutlineDown } from "react-icons/ai";
import CustomSelect from "../../common/CustomSelect";
import WorkersTable from "./WorkersTable";
import { useUnderReview } from "../../../context/underPreview/UnderPreview"; // Assuming the context is named WorkersContext
import debounce from "lodash.debounce";

function WorkersList({data}) {
  const {  filteredData } = useUnderReview(); // Access context
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("latest");

  // Debounced function for filtering data
  const debouncedFilterData = useCallback(
    debounce((search, sort) => {
      filteredData(search, sort);
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFilterData(value, sortOrder); 
  };

  const handleSortChange = (e) => {
    const value = e.target.value;
    setSortOrder(value);
    debouncedFilterData(searchTerm, value); // Call debounced function with updated sort order
  };

  return (
    <div className="w-full p-4 sm:p-6 md:p-10 bg-white my-12 rounded-xl shadow-md">
      <div className="flex items-center flex-wrap w-full justify-between">
        <h3 className="text-md md:text-xl text-black">Workers</h3>
        <div className="flex items-center gap-6 justify-center flex-col sm:flex-row">
          <div className="w-full">
            <div className="relative bg-[#FEBF004D] flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="absolute w-5 h-5 top-2.5 left-2.5 text-mainTextColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                  clipRule="evenodd"
                />
              </svg>
              <input
                className="w-full bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md pl-10 pr-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <CustomSelect
            className="!bg-[#FEBF004D]"
            value={sortOrder}
            onChange={(e) => handleSortChange(e)}
          />
        </div>
      </div>
      <WorkersTable data={data} />
    </div>
  );
}

export default WorkersList;
