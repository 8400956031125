import React from "react";
import underpreview from "../../assets/images/preview.png";
import UnderPreviewContent from "../../components/features/under_preview/UnderPreviewContent";
import HeaderTitle from "../../components/common/HeaderTitle";
import { useUnderReview } from "../../context/underPreview/UnderPreview";
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";
import { useChat } from "../../context/chat/ChatContext";
import ChatPage from "../../components/features/chats/ChatPage";

function Chat() {
  const { chats, loading, error } = useChat();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <HeaderTitle
        title="Chats"
        imgSrc={
          "https://static-00.iconduck.com/assets.00/chat-icon-2048x2048-i7er18st.png"
        }
      />

      <ChatPage data={chats || []} />
    </>
  );
}

export default Chat;
