import React from "react";
import userImage from "../../../assets/images/Ellipse 5.png";
import UserList from "./UserList";

function UserContent({users}) {
  return (
    <div className=" p-4 md:pr-8 md:pl-16 md:pt-8 md:pb-8">
      <div className="flex  items-center justify-between">
        <div className="flex flex-col">
          <h3 className="text-xl font-medium text-primary">Manage Users</h3>
          <h3 className="text-md font-medium text-fiveTextColor">
            Manage and organize your users
          </h3>
        </div>
        <div className="flex items-center justify-center gap-3">
          <img src={userImage} alt="UserImage" className="w-8" />
        </div>
      </div>
        
      <UserList users={users} />
    </div>
  );
}

export default UserContent;
