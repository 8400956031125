import React from "react";
import users from "../../assets/images/users.png";
import UserContent from "../../components/features/users/UserContent";
import HeaderTitle from "../../components/common/HeaderTitle";
import { useUser } from "../../context/users/UsersContext";
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";

function Users() {
  const { userData, loading, error } = useUser();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }
 
  console.log(userData.data);
  return (
    <>
      <HeaderTitle title="Users" imgSrc={users} />
      <UserContent users={userData.data} />
    </>
  );
}

export default Users;
