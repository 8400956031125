import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Dashboard from "./pages/dashboard/DashBoard";
import Login from "./pages/auth/Login";
import AdminAccount from "./pages/admin_account/AdminAccount";
import MonyTransfers from "./pages/mony_transfers/MonyTransfers";
import Transactions from "./pages/transactions/Transactions";
import UnderReview from "./pages/under_review/UnderReview";
import Users from "./pages/users/Users";
import Settings from "./pages/settings/Settings";
import ProtectedRoute from "./components/common/ProtectedRoute"; // Import ProtectedRoute
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "./context/auth/AuthContext";
import { HomeProvider } from "./context/dashBoard/DashBoardContext";
import { UnderReviewProvider } from "./context/underPreview/UnderPreview.js";
import { UserProvider } from "./context/users/UsersContext";
import { TransactionProvider } from "./context/transactions/TransationsContext.jsx";
import { AdminProvider } from "./context/admin/AdminContext.jsx";
import WorkerPreview from "./pages/WorkerPreview/WorkerPreview.js";
import { MoneyTransferProvider } from "./context/mony_transfers/MonyTransfersContext.js";
import Chat from "./pages/chat/Chat.js";
import { ChatProvider } from "./context/chat/ChatContext.js";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Routes>
            {/* Public Login Route */}
            <Route path="/login" element={<Login />} />

            {/* Protected Routes */}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            >
              <Route
                index
                element={
                  <HomeProvider>
                    <Dashboard />
                  </HomeProvider>
                }
              />

              <Route
                path="admin-account"
                element={
                  <AdminProvider>
                    <AdminAccount />
                  </AdminProvider>
                }
              />

              <Route
                path="money-transfers"
                element={
                  <MoneyTransferProvider>
                    <MonyTransfers />
                  </MoneyTransferProvider>
                }
              />
              <Route
                path="chat"
                element={
                  <MoneyTransferProvider>
                    <ChatProvider>
                      {" "}
                      <Chat />
                    </ChatProvider>
                  </MoneyTransferProvider>
                }
              />
              <Route
                path="transactions"
                element={
                  <TransactionProvider>
                    <Transactions />
                  </TransactionProvider>
                }
              />
              <Route
                path="under-review"
                element={
                  <UnderReviewProvider>
                    <UnderReview />
                  </UnderReviewProvider>
                }
              />
              <Route
                path="worker-preview/:id"
                element={
                  <UnderReviewProvider>
                    <WorkerPreview />
                  </UnderReviewProvider>
                }
              />

              <Route
                path="users"
                element={
                  <UserProvider>
                    <Users />
                  </UserProvider>
                }
              />
              <Route path="settings" element={<Settings />} />
            </Route>

            {/* Fallback for 404 */}
            <Route path="*" element={<div>404 - Page Not Found</div>} />
          </Routes>
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
