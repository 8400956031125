import React from "react";

function Loader({ className = "" }) {
  return (
    <div
      className={`   z-50 w-full h-full flex items-center justify-center ${className}`}
    >
      <div class="loader"></div>
    </div>
  );
}

export default Loader;
