import React from 'react'
import copyRight from "../../../assets/images/Copyright.png";
function DashBoardFooter() {
  return (
    <footer className="  flex items-center justify-center bg-primary p-4">
        <div className="flex  justify-end md:justify-start items-center gap-4">
          <img src={copyRight} alt="" />
          <h3 className="text-md text-white md:text-lg">
            Created by NG -Technology{" "}
          </h3>
        </div>
      </footer>
  )
}

export default DashBoardFooter