

import client from '../client'; 


export const fetchDashboardStats = async () => {
  try {
    const response = await client.get('/api/Dashboard/dashboard-stats');
    return response.data; 
  } catch (error) {
    throw new Error("Error fetching dashboard stats: " + error.message);
  }
}; 





export const fetchDashboardCompletedOrders = async () => {
  try {
    const response = await client.get('/api/Dashboard/completed-orders');
    return response.data; 
  } catch (error) {
    throw new Error("Error fetching dashboard stats: " + error.message);
  }
};

 

