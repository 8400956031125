import client from "../client";

export const fetchTransactions = async () => {
  const response = await client.get("/api/OrdersWithOffers/admin/all-orders");
  return response.data;
};
export const getOrderDetails = async (id) => {
  const response = await client.get(
    `api/OrdersWithOffers/admin/order-details/${id}`
  );
  return response.data;
};
