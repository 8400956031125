import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchAdmins } from "../../api/admin/adminApi"; 

const AdminContext = createContext();

export const useAdmin = () => {
  return useContext(AdminContext);
};

export const AdminProvider = ({ children }) => {
  const [adminData, setAdminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchAdmins();
        setAdminData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <AdminContext.Provider value={{ adminData, loading, error }}>
      {children}
    </AdminContext.Provider>
  );
};
