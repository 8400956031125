import React, { useState, useEffect } from "react";
import TransactionItem from "./TransactionItem";
import { Pagination } from "@mui/material";

function TransactionList({ transactionData, fetchOrderDetails }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState(""); 
  const itemsPerPage = 5;

  useEffect(() => {

    if (transactionData.length > 0) {
      setActive(transactionData[0].orderId);
    }
  }, [transactionData]);

  const totalPages = Math.ceil(transactionData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactionData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className="p-2 m-2 md:p-4 md:m-3 border rounded-lg">
      <div className="w-[90%] flex-col gap-3 flex mx-auto">
        {currentItems.map((transaction) => (
          <div key={transaction.orderId} onClick={() => { 
            setActive(transaction.orderId); 
            fetchOrderDetails(transaction.orderId);
          }}>
            <TransactionItem
              isActive={active === transaction.orderId}
              transaction={transaction}
            />
          </div>
        ))}

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          className="mt-4 flex justify-center"
        />
      </div>
    </div>
  );
}

export default TransactionList;
