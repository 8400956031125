import React, { createContext, useContext, useEffect, useState } from "react";
import { getChats } from "../../api/Chat/ChatApi"; // Replace with your actual API function for fetching chats.

const ChatContext = createContext();

// Hook to use the ChatContext
export const useChat = () => {
  return useContext(ChatContext);
};

// Chat Provider Component
export const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState(null); // Stores chat data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Function to fetch chats from API
  const fetchChats = async () => {
    setLoading(true);
    try {
      const data = await getChats(); // Replace with your chat API function
      console.log("Chat data:", data);
      setChats(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch chats on component mount
  useEffect(() => {
    fetchChats();
  }, []);

  return (
    <ChatContext.Provider value={{ chats, loading, error }}>
      {children}
    </ChatContext.Provider>
  );
};
