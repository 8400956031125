import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import Popover from "@mui/material/Popover";
import Pagination from "@mui/material/Pagination";
import { AiOutlineDelete } from "react-icons/ai"; 
import { useUser } from "../../../context/users/UsersContext";
import Swal from 'sweetalert2';

function UserListTable({ users }) { 
  const { handleDeleteUser } = useUser();  
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const paginatedUsers = users.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const totalPages = Math.ceil(users.length / pageSize);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const confirmDelete = (userId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This user will be permanently deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteUser(userId); 
        Swal.fire('Deleted!', 'The user has been deleted.', 'success');
      }
    });
  };

  return (
    <div className="p-3 sm:p-6 md:p-8 my-10 border rounded-md shadow-md">
      <div className="overflow-x-auto w-[350px] mx-auto sm:w-[500px] md:[550px] xl:w-[1000px] 2xl:w-[1300px]">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="p-4 text-left text-sm font-medium text-primary border-gray-200">
                Name
              </th>
              <th className="p-4 text-left text-sm font-medium text-primary border-gray-200">
                Account ID
              </th>
              <th className="p-4 text-left text-sm font-medium text-primary border-gray-200">
                Email
              </th>
              <th className="p-4 text-left text-sm font-medium text-primary border-gray-200">
                Address
              </th>
              <th className="p-4 text-left text-sm font-medium text-primary border-gray-200">
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedUsers.map((user, index) => (
              <tr key={index} className="hover:bg-slate-50">
                <td className="p-4 text-fiveTextColor text-sm">{user.fullName}</td>
                <td className="p-4 text-fiveTextColor text-sm">{user.id.slice(0, 4)}</td>
                <td className="p-4 text-fiveTextColor text-sm">{user.email}</td>
                <td className="p-4 text-fiveTextColor text-sm">{user.address ?? "Unknown"}</td>
                <td className="p-4 text-fiveTextColor text-sm">
          
                  <AiOutlineDelete 
                    onClick={() => confirmDelete(user.id)} 
                    size={25} 
                    className="text-red-500 cursor-pointer ml-4" 
                  />
                </td>

              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          className="mt-4 flex justify-center"
        />
      </div>

    </div>
  );
}

export default UserListTable;
