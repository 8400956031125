import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineArrowLeft } from "react-icons/ai";
import AdminComponent from "./AdminComponent";
import AddAdmin from "./AddAdmin";

function AdminAccountContent({ data }) {
  console.log("AdminAccountContent data", data);
  const [isAddFormVisible, setIsAddFormVisible] = useState(false);

  const handleToggle = () => {
    setIsAddFormVisible(!isAddFormVisible);
  };

  return (
    <div className="p-4 sm:p-6">
      {/* <div className="w-full flex items-center justify-center md:justify-end">
        <button
          onClick={handleToggle}
          className="outline-none border-none rounded-md bg-primary transition-all focus:scale-110 hover:bg-primaryHovered text-white px-4 py-2 flex items-center gap-6"
        >
          {isAddFormVisible ? (
            <>
              <AiOutlineArrowLeft size={25} />
              <h3 className="text-sm md:text-md lg:text-lg">Return</h3>
            </>
          ) : (
            <>
              <AiOutlinePlus size={25} />
              <h3 className="text-sm md:text-md lg:text-lg">Add officials</h3>
            </>
          )}
        </button>
      </div> */}

      {isAddFormVisible && <AddAdmin />}

      {!isAddFormVisible && (
        <>
          {data?.map((data) => (
            <AdminComponent key={data.id} data={data} />
          ))}
        </>
      )}
    </div>
  );
}

export default AdminAccountContent;
