import React from "react";
import monytransfers from "../../assets/images/transactions.png";
import MonyTransfersContent from "../../components/features/mony_transfers/MonyTransfersContent";
import HeaderTitle from "../../components/common/HeaderTitle";
function MonyTransfers() {
  return (
    <>
      <HeaderTitle title="Mony Transfers" imgSrc={monytransfers} />
      <MonyTransfersContent />
    </>
  );
}

export default MonyTransfers;
