import React, { useEffect, useState } from "react";
import underpreview from "../../assets/images/preview.png";
import HeaderTitle from "../../components/common/HeaderTitle";
import { useUnderReview } from "../../context/underPreview/UnderPreview";
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";
import WorkerPreviewContent from "../../components/features/worker_preview/WorkerPreviewContent";
import { useParams } from "react-router-dom";

function WorkerPreview() {
  const { id } = useParams();

  const { workerData, fetchWorkerById, loading, error } = useUnderReview();

  useEffect(() => {
    fetchWorkerById(id);
  }, [id]);

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <>
      <HeaderTitle
        className="!bg-primary !text-white"
        title="Under Review"
        imgSrc={underpreview}
      />
{    workerData &&  <WorkerPreviewContent data={workerData.data} id={id} />}
    </>
  );
}

export default WorkerPreview;
