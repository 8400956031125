import React, { useState } from "react";
import  ChatCard  from './ChatCard'
function ChatPage({ data }) {
  return (
    <div className="p-4 space-y-4">
      {data.data.map((chat) => (
        <ChatCard key={chat.conversationId} chat={chat} />
      ))}
    </div>
  );
}





export default ChatPage;
