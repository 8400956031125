import React from "react";
import transactions from "../../assets/images/transactions.png";
import HeaderTitle from "../../components/common/HeaderTitle";
import TransactionContent from "../../components/features/transactions/TransactionContent";
import { useTransactions } from "../../context/transactions/TransationsContext.jsx";
import Loader from "../../components/common/Loader.js";
import Error from "../../components/common/Error.js";
function Transactions() {
  const {
    transactionData,
    loading,
    error,
  
  } = useTransactions();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  console.log(useTransactions);
  return (
    <>
      <HeaderTitle title=" Transactions" imgSrc={transactions} />
      <TransactionContent transactionData={transactionData.data} />
    </>
  );
}

export default Transactions;
