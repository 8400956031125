import React from "react";
import { AiOutlineDown } from "react-icons/ai"; // Importing the dropdown icon

const CustomSelect = ({ className, onChange, value }) => {
  return (
    <div className="relative">
      <select
        className={`w-36 px-3  py-2 bg-bg placeholder:text-slate-400 text-slate-700 text-sm border rounded-3xl shadow-sm focus:outline-none focus:ring-2 focus:ring-primary transition duration-300 ease-in-out cursor-pointer appearance-none ${className}`}
        onChange={onChange}
        value={value}
      >
        <option value="latest">newest</option>
        <option value="oldest">oldest</option>
      </select>
      <div className="absolute top-1/2 transform -translate-y-1/2 right-3 pointer-events-none">
        <AiOutlineDown size={20} className="text-slate-400" />
      </div>
    </div>
  );
};

export default CustomSelect;
