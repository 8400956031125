// src/components/ProtectedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  // Check if the token is present in localStorage
  const token = localStorage.getItem("authToken");

  // If the token is not found, redirect to /login
  return token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
