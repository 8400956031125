import client from "../client";

export const fetchUnderReviewData = async (search = "", sort = "newest") => {
  const response = await client.get(
    `/api/Dashboard/get-all-workers?searchTerm=${search}&sortOrder=${sort}`
  );
  return response.data;
};

export const fetchWorker = async (id) => {
  const response = await client.get("/api/Dashboard/get-worker/" + id);
  console.log("from api worker", response.data);
  return response.data;
};

export const changeWorkerStatus = async (id, status, message) => {
  console.log("from api worker", status, id);
  const response = await client.put(`/api/Worker/update-worker-status/${id}`, {
    status: status,
    message: message,
  });

  console.log("from api worker", response.data);
  return response.data;
};
