import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";

const MonyTransfersTable = ({ data }) => {
  const [page, setPage] = useState(1);
  const rowsPerPage = 10; 
  const pageCount = Math.ceil(data.length / rowsPerPage);


  const paginatedData = data.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="overflow-x-auto w-[400px] sm:w-[500px] md:[650px] xl:w-[1024px] 2xl:w-[1300px]">
      <table className="min-w-full bg-white">
        <thead className="whitespace-nowrap">
          <tr className="border-b">
            <th className="pl-4 w-8">
              <input
                id="checkbox"
                type="checkbox"
                className="hidden rounded-md peer"
              />
              <label
                htmlFor="checkbox"
                className="relative flex items-center justify-center p-0.5 peer-checked:before:hidden before:block before:absolute before:w-full before:h-full before:bg-gray-50 w-5 h-5 cursor-pointer bg-blue-500 border border-gray-400 rounded overflow-hidden"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-full fill-white"
                  viewBox="0 0 520 520"
                >
                  <path d="M79.423 240.755a47.529 47.529 0 0 0-36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 0 1 .128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 0 1-15.585.843l-83.94-76.386a47.319 47.319 0 0 0-31.939-12.438z" />
                </svg>
              </label>
            </th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Invoice ID</th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Date</th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Invoice ID</th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Customer</th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Payable Amount</th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Paid Amount</th>
            <th className="p-4 text-left text-[14px] sm:text-sm md:text-md font-medium text-black">Due</th>
          </tr>
        </thead>
        <tbody className="whitespace-nowrap">
          {paginatedData.map((transfer, index) => (
            <tr key={index} className="border-b">
              <th className="pl-4 w-8">
                <input
                  id={`checkbox-${index}`}
                  type="checkbox"
                  className="hidden rounded-md peer"
                />
                <label
                  htmlFor={`checkbox-${index}`}
                  className="relative flex items-center justify-center p-0.5 peer-checked:before:hidden before:block before:absolute before:w-full before:h-full before:bg-gray-50 w-5 h-5 cursor-pointer bg-blue-500 border border-gray-400 rounded overflow-hidden"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-full fill-white"
                    viewBox="0 0 520 520"
                  >
                    <path d="M79.423 240.755a47.529 47.529 0 0 0-36.737 77.522l120.73 147.894a43.136 43.136 0 0 0 36.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 0 1 .128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 0 0-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 0 1-15.585.843l-83.94-76.386a47.319 47.319 0 0 0-31.939-12.438z" />
                  </svg>
                </label>
              </th>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-primary">{transfer.invoiceId}</td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">{transfer.date}</td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">{transfer.invoiceId}</td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">{transfer.customerName}</td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">{transfer.payableAmount}</td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">{transfer.paidAmount}</td>
              <td className="p-4 text-left text-[14px] sm:text-sm md:text-md font-normal text-fiveTextColor">{transfer.due}</td>
            </tr>
          ))}
        </tbody>
      </table>
      
      {/* Pagination component */}
      <div className="flex justify-center my-4">
        <Pagination
          count={pageCount}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </div>
    </div>
  );
};

export default MonyTransfersTable;
