// src/components/CustomInput.js

import React from 'react';

const CustomInput = ({
  label,
  type = 'text',
  placeholder,
  value,
  onChange,
  error,
  inputClassName = '', 
  labelClassName = '', 
  parentClassName = '',  
  placeholderClassName = '' 
}) => {
  return (
    <div className={`mb-8 font-poppins ${parentClassName}`}>
      {label && (
        <label
          className={`block font-poppins text-sm font-medium text-[#919499] mb-2 ${labelClassName}`}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`block w-full py-2 px-4 border rounded-lg outline-none focus:border-primary focus:border-2 placeholder-[#A0A0A0] ${placeholderClassName} ${
          error ? 'border-red-500' : 'border-gray-300'
        } ${inputClassName}`}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default CustomInput;
