import React from "react";
import underpreview from "../../assets/images/adminSettings.png";
import AdminAccountContent from "../../components/features/admin_account/AdminAccountContent";
import { useAdmin } from "../../context/admin/AdminContext";
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";
import HeaderTitle from "../../components/common/HeaderTitle";
function AdminAccount() {
  const { adminData, loading, error } = useAdmin();
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <Error />;
  }
  return (
    <>
      <HeaderTitle title="Admin Account" imgSrc={underpreview} />
      <AdminAccountContent data={adminData} />
    </>
  );
}

export default AdminAccount;
