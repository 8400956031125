import React from "react";
import camera from "../../../assets/images/Camera.png";
import CustomInput from "../../common/CustomInpute";
import CustomButton from "../../common/CustomBtn";
function AddAdmin() {
  return (
    <div className=" m-4 md:m-12 ">
      <div className="flex justify-center md:justify-start items-center gap-7 mt-7 p-2 md:p-4">
        <div className="w-32 h-32 cursor-pointer shadow-xl bg-primary  flex items-center justify-center rounded-full">
          <img src={camera} alt="Camera" />
        </div>
      </div>

      <div className="flex items-center justify-center flex-wrap my-10 gap-10">
        <div className="flex flex-col gap-6">
          <CustomInput
            parentClassName="!m-0"
            label="First name"
            placeholder="First name" 
            // 
            onChange={() => {}}
            error={""}
            labelClassName="!text-mainTextColor !text-sm !font-normal"
            inputClassName="!text-black w-full !sm:!w-[400px] lg:!w-[450px]!text-[14px] !border-none !rounded shadow-md !focus:!border !border-gray-300"
          />
          <CustomInput
            parentClassName="!m-0"
            label="Phone number"
            placeholder="Phone number"
            type="phone"
            onChange={() => {}}
            error={""}
            labelClassName="!text-mainTextColor !text-sm !font-normal"
            inputClassName="!text-black w-full !sm:!w-[400px] lg:!w-[450px] !text-[14px] !border-none !rounded shadow-md !focus:!border !border-gray-300"
          />
          <CustomInput
            parentClassName="!m-0"
            label="Password"
            type="password"
            placeholder="12312"
            onChange={() => {}}
            error={""}
            labelClassName="!text-mainTextColor !text-sm !font-normal"
            inputClassName="!text-black w-full !sm:!w-[400px] lg:!w-[450px] !text-[14px] !border-none !rounded shadow-md !focus:!border !border-gray-300"
          />
        </div>
        <div className="flex flex-col gap-6">
          <CustomInput
            parentClassName="!m-0"
            label="Last name"
            placeholder="Last name"
            onChange={() => {}}
            error={""}
            labelClassName="!text-mainTextColor !text-sm !font-normal"
            inputClassName="!text-black w-full !sm:!w-[400px] lg:!w-[450px]!text-[14px] !border-none !rounded shadow-md !focus:!border !border-gray-300"
          />
          <CustomInput
            parentClassName="!m-0"
            label="Email"
            placeholder="mostafa@gmail.com"
            type="email"
            onChange={() => {}}
            error={""}
            labelClassName="!text-mainTextColor !text-sm !font-normal"
            inputClassName="!text-black w-full !sm:!w-[400px] lg:!w-[450px] !text-[14px] !border-none !rounded shadow-md !focus:!border !border-gray-300"
          />
          <CustomInput
            parentClassName="!m-0"
            label="User Type"
            type="text"
            placeholder="admin"
            onChange={() => {}}
            error={""}
            labelClassName="!text-mainTextColor !text-sm !font-normal"
            inputClassName="!text-black w-full !sm:!w-[400px] lg:!w-[450px] !text-[14px] !border-none !rounded shadow-md !focus:!border !border-gray-300"
          />
        </div>
      </div>
      <div className=" ">
        <CustomButton
          label="Add an Admin"
          className=" w-full !shadow-md !rounded-md p-2 sm:!w-[200px] "
          onClick={() => {}}
        />
      </div>
    </div>
  );
}

export default AddAdmin;
