import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { getMoneyTransfers } from "../../api/mony_transafers/monyTransferApi";

const MoneyTransferContext = createContext();

export const useMoneyTransfer = () => useContext(MoneyTransferContext);

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

export const MoneyTransferProvider = ({ children }) => {
  const [moneyTransfers, setMoneyTransfers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState("");

  const fetchMoneyTransfers = useCallback(
    debounce(async (searchQuery) => {
      setLoading(true);
      try {
        const data = await getMoneyTransfers(searchQuery);
        setMoneyTransfers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  useEffect(() => {
    fetchMoneyTransfers("");
  }, [fetchMoneyTransfers]);

  useEffect(() => {
    if (query) {
      fetchMoneyTransfers(query);
    }
  }, [query, fetchMoneyTransfers]);

  const updateQuery = (newQuery) => {
    setError(null); // Clear previous errors
    setQuery(newQuery);
  };

  return (
    <MoneyTransferContext.Provider
      value={{ moneyTransfers, loading, error, updateQuery }}
    >
      {children}
    </MoneyTransferContext.Provider>
  );
};
