import client from "../client";


export const fetchUsers = async (search = "", sort = "newest") => {
  const response = await client.get(
    `/api/Dashboard/get-all-users?searchTerm=${search}&sortOrder=${sort}`
  );
  return response.data;
};
 




export const deleteUser = async (userId) => {
  try { 

    const response = await client.delete(
      `/api/Dashboard/delete-user/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw new Error("Error deleting user");
  }
};
