import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CustomInput from "../../components/common/CustomInpute";
import CustomButton from "../../components/common/CustomBtn";
import { useAuth } from "../../context/auth/AuthContext"; 
import "../../index.css";

const Login = () => {
  const { login } = useAuth(); 
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate

  const validateForm = () => {
    if (!email) {
      setError("Email is required.");
      return false;
    }
    if (!password) {
      setError("Password is required.");
      return false;
    }
    setError("");
    return true;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    try {
      await login({ email, password });
      navigate("/"); // Redirect on successful login
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
    }
  };

  return (
    <main className="bg-[#F8F8F8] h-[100vh] w-[100vw] flex items-center justify-center">
      <div className="bg-white mx-auto w-[90vw] sm:w-[50vw] md:w-[40vw] p-10 rounded-2xl shadow-sm">
        <h3 className="text-center font-bold text-black text-lg sm:text-xl mb-4 border-gray-300">
          Login as Administrator
        </h3>
        
        <CustomInput
          label="Email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={error.includes("Email") ? error : ""}
        />
        
        <CustomInput
          label="Password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={error.includes("Password") ? error : ""}
        />
        
        {error && !error.includes("Email") && !error.includes("Password") && (
          <p className="text-red-500 text-sm mt-2">{error}</p>
        )}
        
        <div className="mb-8">
          <CustomButton label="Sign in" onClick={handleLogin} />
        </div>
      </div>
    </main>
  );
};

export default Login;
