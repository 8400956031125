import React from 'react'

function DataItem({title ,dataItem}) {
  return (
    <div className="flex flex-col gap-2"> 
    <h3 className="text-xl font-medium text-primary">{title}</h3> 
    <div className="w-[350px]   lg:w-[450px] p-5 rounded-md bg-white shadow-md"> 
      {dataItem}
    </div>

  </div>
  )
}

export default DataItem