import React from "react";
import UserInfo from "./UserInfo";
import Offer from "./Offer";
import Loader from "../../common/Loader";
import Error from "../../common/Error";

function SelectedUser({ data, loading, error }) {
  if (error) {
    return <Error />;
  }

  console.log("Selected user data", data);
  
  return (
    <div className="w-full lg:w-1/3 p-4 lg:max-h-[80vh] md:overflow-y-auto">
      {loading ? (
        <Loader />
      ) : (
        <>
          <UserInfo data={data} />
          <Offer data={data} />
        </>
      )}
    </div>
  );
}

export default SelectedUser;
