// src/components/CustomButton.js

import React from 'react';

const CustomButton = ({ label, onClick, type = 'button', disabled = false, variant = 'primary', className = '' }) => {
  const baseStyle = "w-full py-4 text-sm rounded-md transition duration-200 focus:outline-none";

  const variantStyles = {
    primary: "bg-primary text-white hover:bg-primaryHovered focus:bg-primaryHovered focus:ring-primary",
    secondary: "bg-gray-300 text-gray-800 hover:bg-gray-400",
    danger: "bg-red-500 text-white hover:bg-red-600",
    outline: "border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white",
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyle} ${variantStyles[variant]} ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}
    >
      {label}
    </button>
  );
};

export default CustomButton;
