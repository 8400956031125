import React from 'react'
 import  settings  from '../../assets/images/Setting.png'
function Settings() {
  return (
    <>
      <header className=" bg-white shadow-sm p-4 ">
      <div className=' flex  justify-end md:justify-start items-center gap-4'> 

  <img src={settings} className='w-8' alt="" />
  <h3 className='text-md md:text-lg'>Settings</h3>
</div>
      </header>
    </>
  )
}

export default Settings