import React from "react";
import numberOfPurbless from "../../../assets/images/Assignment turned in.png";
import numberOfOrange from "../../../assets/images/Assignment turned in-2.png";
import numberOfTeal from "../../../assets/images/Assignment turned in-1.png";
import update from "../../../assets/images/Update.png";
import CustomButton from "../../common/CustomBtn";
import Pie from "../../common/Pie";
function DashBoardSatistics({ dataStatics }) {
  return (
    <div className="bg-white  rounded-xl   shadow-sm">
      <div className="p-3  bg-primary rounded-t-xl">
        <h3 className="text-white">statistics</h3>
      </div>
      <div className="  mt-3 flex justify-around gap-8 items-center p-4 flex-wrap">
        <Pie percentage={dataStatics.clientWorkerRatioPercentage.toFixed(2)} colour="teal" />

        <div className="flex flex-col gap-12">
          <div className="flex gap-12">
            <div className="flex  gap-4">
              <img src={numberOfPurbless} alt="2" className="w-6 h-6" />
              <div>
                <h3 className=" text-primary text-sm mb-2  font-medium">
                  Numbers Of Clients
                </h3>
                <h3 className="text-black text-sm font-semibold">
                  {dataStatics.clientCount}
                </h3>
              </div>
            </div>
            <div className="flex  gap-4">
              <img src={numberOfTeal} alt="2" className="w-6 h-6" />
              <div>
                <h3 className=" text-primary text-sm mb-2  font-medium">
                  Numbers Of Workers
                </h3>
                <h3 className="text-black text-sm font-semibold">
                  {dataStatics.workerCount}
                </h3>
              </div>
            </div>
          </div>
          <div className="flex  gap-12">
            <div className="flex  gap-4">
              <img src={numberOfPurbless} alt="2" className="w-6 h-6" />
              <div>
                <h3 className=" text-primary text-sm mb-2  font-medium">
                  Numbers Of Orders
                </h3>
                <h3 className="text-black text-sm font-semibold">
                  {dataStatics.orderCount}
                </h3>
              </div>
            </div>
            <div className="flex  gap-4">
              <img src={numberOfTeal} alt="2" className="w-6 h-6" />
              <div>
                <h3 className=" text-primary text-sm mb-2  font-medium">
                  Numbers Of Admin Accounts
                </h3>
                <h3 className="text-black text-sm font-semibold">
                  {dataStatics.adminCount}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F8F8F8] w-full rounded-sm  md:w-[400px] p-3 md:p-4 ">
          <div className=" flex justify-between items-center">
            <h3 className="text-primary text-lg  mb-2  font-medium">
              Credit Score Ranking
            </h3>
            <img src={update} className="w-6" alt="" />
          </div>
          <p className="text-black font-thin text-[12px] mb-7">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
          <CustomButton
            className="!w-[120px] !py-2 "
            label="See More .."
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
}

export default DashBoardSatistics;
