import React, { useState } from "react";
import WorkersList from "./WorkersList";

function UnderPreviewContent({ data }) {
  const [filterStatus, setFilterStatus] = useState("Waiting for approval");

  // Filter data based on selected status
  const filteredData = data.filter((worker) => worker.status === filterStatus);

  return (
    <div className="w-full p-4 sm:p-6 md:p-10 ">
      {/* Status Filter */}
      <div className="bg-white p-4 rounded-s-lg flex items-center gap-3 sm:gap-5 md:gap-8 lg:gap-10">
        {["Waiting for approval", "Certified"].map((status) => (
          <h3
            key={status}
            onClick={() => setFilterStatus(status)}
            className={`text-sm sm:text-md lg:text-xl cursor-pointer ${
              filterStatus === status ? "text-primary" : "text-mainTextColor"
            }`}
          >
            {status}
          </h3>
        ))}
      </div>

      {/* Workers List */}
      <WorkersList data={filteredData} />
    </div>
  );
}

export default UnderPreviewContent;
