import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUsers, deleteUser } from "../../api/users/usersApi";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getUsers = async () => {
    try {
      const data = await fetchUsers();
      setUserData(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  // Delete user function
  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      getUsers();
    } catch (err) {
      setError(err);
    }
  };

  const filteredData = async (search, sort) => {
    const data = await fetchUsers(search, sort);
    console.log(data);
    setUserData(data);
  };

  return (
    <UserContext.Provider
      value={{ userData, loading, error, handleDeleteUser, filteredData }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
