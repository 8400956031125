import React, { createContext, useContext, useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import client from "../../api/client"; // Adjust this path as necessary

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setUser({ isAuthenticated: true });
    }
  }, []);

  const login = async (credentials) => {
    try {
      const response = await client.post(
        "/api/AccountAdmin/login",
        credentials
      );
      const userData = response.data.data;
      console.log("data from context");

      localStorage.setItem("authToken", userData.token);
      setUser(userData);
      setUser({ isAuthenticated: true, data: userData });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setUser(null);
    queryClient.clear();
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
