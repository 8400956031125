import React from "react";
import Elisis from "../../../assets/images/Ellipse 5.png";
import { useHome } from "../../../context/dashBoard/DashBoardContext";

function OrderAndTransfers({ data }) {
  const { completedOrder } = useHome();
  console.log("completetd");
  console.log(completedOrder);
  return (
    <div className="flex w-full justify-center flex-wrap items-center gap-7">
      <div className="flex-1 bg-white rounded-lg p-6 shadow-sm ">
        <div className="flex justify-between  items-center">
          <h3 className="text-xl font-medium text-[#333333]">
            Completed orders
          </h3>
          <h3 className="text-xl font-medium text-[#333333]">
            {data.completedOrderCount}
          </h3>
        </div>

        {completedOrder?.map((item, idx) => (
          <div key={idx} className="flex justify-between items-center mt-4">
            <img src={item.userImage} className="w-12  rounded-full" alt="" />
            <h3 className="text-sm md:text-md  text-secondTextColor">
              {" "}
              $
            </h3>
            <h3 className="text-sm md:text-md  text-secondTextColor">
              {item.created.split("T")[0]}{" "}
            </h3>
            <h3 className="text-sm md:text-md  text-secondTextColor">
              {" "}
              {item.price}{" "}
            </h3>
            <h3 className="text-sm md:text-md  text-fourthTextColor">
              {" "}
              {item.status}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderAndTransfers;
