// src/pages/under_review/UnderReview.js

import React from "react";
import underpreview from "../../assets/images/preview.png";
import UnderPreviewContent from "../../components/features/under_preview/UnderPreviewContent";
import HeaderTitle from "../../components/common/HeaderTitle";
import { useUnderReview } from "../../context/underPreview/UnderPreview";
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";

function UnderReview() {
  const { underReviewData, loading, error } = useUnderReview();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <>
      <HeaderTitle title="Under Review" imgSrc={underpreview} /> 
      
      <UnderPreviewContent data={underReviewData.data ||  []} />
    </>
  );
}

export default UnderReview;
