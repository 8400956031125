// src/pages/dashboard/DashBoard.js

import React from "react";
import dashBoard from "../../assets/images/dashboardIcon.png";

import DashBoardContent from "../../components/features/dashboard/DashBoardContent";
import HeaderTitle from "../../components/common/HeaderTitle";
import DashBoardFooter from "../../components/features/dashboard/DashBoardFooter";
import { useHome } from "../../context/dashBoard/DashBoardContext"; // Ensure this points to your HomeContext
import Loader from "../../components/common/Loader";
import Error from "../../components/common/Error";

function DashBoard() {
  const { homeData, loading, error } = useHome();   
  console.log('from dashboard')
  console.log(homeData);
  if (loading) {
    return <Loader />; 
  }

  if (error) {
    return <Error />; 
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <HeaderTitle title="Money Transfers" imgSrc={dashBoard} />
      <main className="flex-1 p-4 md:pl-16 md:pr-8 md:pt-8 md:pb-8">
        {/* Pass homeData as a prop to DashBoardContent if needed */}
        <DashBoardContent data={homeData.data} />
      </main>
      <DashBoardFooter />
    </div>
  );
}

export default DashBoard;
