function MessageCard({ message, chat }) {
  const isWorkerMessage = message.senderId === chat.workerId;

  return (
    <div
      className={`flex ${
        isWorkerMessage ? "justify-end" : "justify-start"
      } items-start space-x-2`}
    >
      {!isWorkerMessage && (
        <img
          src={message.recipientProfilePicture}
          alt={message.recipientName}
          className="w-8 h-8 rounded-full"
        />
      )}
      <div
        className={`px-4 py-1 rounded-lg text-[10px] ${
          isWorkerMessage
            ? "bg-blue-500 text-white"
            : "bg-gray-200 text-gray-800"
        }`}
      >
        <p>{message.content}</p>
        <span className="block text-xs text-gray-900 mt-1">
          {new Date(message.sentAt).toLocaleString()}
        </span>
      </div>
      {isWorkerMessage && (
        <img
          src={message.senderProfilePicture}
          alt={message.senderName}
          className="w-8 h-8 rounded-full"
        />
      )}
    </div>
  );
}

export default MessageCard;
