import React from "react";

function UserWorkingCard({ imgSrc, text }) {
  return (
    <div className="flex flex-col p-2  border-[#C0C0C0] border rounded-xl items-center justify-center gap-2">
      <img src={imgSrc} alt={text} className="w-8 h-8 md:w-16 md:h-16 object-cover" />
      <p className="text-xs  text-center">{text}</p>
    </div>
  );
}

export default UserWorkingCard;
