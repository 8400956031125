// src/pages/Home.js

import React from "react";
import Sidebar from "../../components/common/SideBar";
import { Outlet } from "react-router-dom";

const Home = () => {
  return (
    <div className="flex ml-0 lg:ml-64  h-[100vh] ">
      <Sidebar />
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  );
};

export default Home;
