import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchTransactions, getOrderDetails } from "../../api/transactions/transactionApi";

const TransactionContext = createContext();

export const useTransactions = () => {
  return useContext(TransactionContext);
};

export const TransactionProvider = ({ children }) => {
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  

  const [orderDetailsLoading, setOrderDetailsLoading] = useState(false);
  const [orderDetailsError, setOrderDetailsError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await fetchTransactions();
        console.log("from transaction api", data);
        setTransactionData(data);

        if (data.data.length > 0) {
  
          const firstOrderDetails = await getOrderDetails(data.data[0].orderId);
          console.log("first order details", firstOrderDetails);
          setSelectedOrderDetails(firstOrderDetails.data);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const fetchOrderDetails = async (id) => {
    setOrderDetailsLoading(true); 
    setOrderDetailsError(null);

    try {
      const details = await getOrderDetails(id);
      setSelectedOrderDetails(details.data); 
      return details; 
    } catch (err) {
      setOrderDetailsError(err.message);
    } finally {
      setOrderDetailsLoading(false); 
    }
  };

  return (
    <TransactionContext.Provider value={{
      transactionData,
      loading,
      error,
      selectedOrderDetails,
      fetchOrderDetails,
      orderDetailsLoading, 
      orderDetailsError,   
    }}>
      {children}
    </TransactionContext.Provider>
  );
};
